import Vue from "vue";
import PinButton from "./PinButton.vue";
import GdPinButton from "./GdPinButton.vue";
import JxPinButton from "./JxPinButton.vue";
import ProfileOverview from "./profile/Overview.vue";
import ProfileCollection from "./profile/Collection.vue";
import ProfileOrders from "./profile/Orders.vue";

Vue.component("pin-button", PinButton);
Vue.component("gd-pin-button", GdPinButton);
Vue.component("jx-pin-button", JxPinButton);
Vue.component("profile-overview", ProfileOverview);
Vue.component("profile-collection", ProfileCollection);
Vue.component("profile-orders", ProfileOrders);

// disable tooltip
Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
    el: "#app"
});
