var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inited
    ? _c("div", { staticClass: "row", class: _vm.elClass }, [
        _c("div", { staticClass: "col-md-12 ml-auto mr-auto" }, [
          _c("h4", { staticClass: "title" }, [_vm._v("最近浏览")]),
          _vm._v(" "),
          _vm.hasRecentlyVisitedRecords
            ? _c(
                "div",
                { staticClass: "row collections" },
                _vm._l(
                  _vm.overviews.recent_lectures,
                  function (lecture, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "col-md-3" },
                      [
                        _c("lecture-card", {
                          attrs: {
                            elClass: "card-less-padding",
                            lecture: lecture,
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _c("h6", { staticClass: "text-center text-muted" }, [
                _vm._v("你还没有访问任何视频讲座"),
              ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }