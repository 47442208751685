$(function () {
    if (typeof BMap != 'undefined' && $('#contactUsMap').length) {
        const map = new BMap.Map('contactUsMap'),
            lat = 118.758043,
            lng = 31.979639,
            level = 12;
        const point = new BMap.Point(lat, lng);
        const marker = new BMap.Marker(point);

        map.centerAndZoom(point, level);
        map.enableDragging();
        map.addOverlay(marker);
    }
});
