var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table table-bordered" }, [
    _c("thead", { staticClass: "thead-light" }, [
      _c("tr", [
        _c("th", { staticClass: "text-left small", attrs: { colspan: "6" } }, [
          _c("strong", [_vm._v(_vm._s(_vm.order.created_at))]),
          _vm._v(" "),
          _c("span", { staticClass: "text-muted ml-3" }, [
            _vm._v("订单编号: " + _vm._s(_vm.order.sn)),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.order.items, function (item, index) {
        return _c("tr", { key: index }, [
          _c("td", { staticClass: "text-left", attrs: { width: "320" } }, [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-left mr-3" }, [
                _c("img", {
                  staticClass: "rounded mb-0",
                  attrs: { src: item.goods.thumb, alt: "thumb" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "media-body" }, [
                _c("h6", { staticClass: "my-0" }, [
                  _vm._v(_vm._s(item.goods.name)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "small mb-0" }, [
                  _vm._v(
                    "观看天数: " +
                      _vm._s(_vm.findItemOption(item.options, "viewable_days"))
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("td", { attrs: { width: "70" } }, [
            _vm.itemOriginalPrice(item)
              ? _c("p", { staticClass: "text-line-through text-muted mb-0" }, [
                  _vm._v("￥" + _vm._s(_vm.itemOriginalPrice(item))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("￥" + _vm._s(item.price)),
            ]),
          ]),
          _vm._v(" "),
          _c("td", { attrs: { width: "50" } }, [_vm._v(_vm._s(item.count))]),
          _vm._v(" "),
          index === 0
            ? _c(
                "td",
                {
                  attrs: {
                    rowspan: _vm.itemRowSpan(_vm.order.items),
                    width: "80",
                  },
                },
                [
                  _vm.orderOriginalPrice
                    ? _c(
                        "p",
                        { staticClass: "text-line-through text-muted mb-0" },
                        [_vm._v("￥" + _vm._s(_vm.orderOriginalPrice))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-weight-bold mb-0" }, [
                    _vm._v("￥" + _vm._s(_vm.order.sub_total)),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          index === 0
            ? _c(
                "td",
                {
                  attrs: {
                    rowspan: _vm.itemRowSpan(_vm.order.items),
                    width: "80",
                  },
                },
                [_c("small", [_vm._v(_vm._s(_vm.order.status_text))])]
              )
            : _vm._e(),
          _vm._v(" "),
          index === 0
            ? _c(
                "td",
                { attrs: { rowspan: _vm.itemRowSpan(_vm.order.items) } },
                [
                  _c("p", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-light btn-sm",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeOrder(_vm.order)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.removeButtonText(_vm.order)))]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.canContinuePay(_vm.order)
                    ? _c("p", [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-sm btn-warning",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.getPaymentCode(_vm.order)
                              },
                            },
                          },
                          [_vm._v("立即支付")]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }