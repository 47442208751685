<template>
    <div v-if="inited" :class="elClass">
        <div v-if="hasCollections" class="row">
            <div class="col-md-3" v-for="(collect, index) in collections.data" :key="index">
                <lecture-card 
                    el-class="card-less-padding"
                    :lecture="collect.lecture" 
                    :remove="{enable: true, desc: '你可以稍后重新收藏', title: '是否移除该收藏?'}"
                    @remove-lecture="unlike(collect.lecture, index)"
                ></lecture-card>
            </div>
        </div>
        <h3 v-else class="text-muted text-center">尚未收藏任何条目</h3>

        <div v-if="hasCollections" class="d-flex mt-2 justify-content-end">
            <pagination :data="collections" @pagination-change-page="paginator"></pagination>
        </div>
    </div>
</template>

<script>
import { ProfileService } from "../../../services/ProfileService";
import LectureCard from "../../cards/LectureCard.vue";
import Pagination from 'laravel-vue-pagination';

export default {
    name: 'ProfileCollection',

    props: {
        elClass: {
            type: [Object, String]
        }
    },

    data() {
        return {
            profileService: null,
            inited: false,
            collections: null
        }
    },

    mounted() {
        this.profileService = new ProfileService;

        this.profileService.hub().$on('init-collections', e => {
            this.initCollections();
        });
    },

    methods: {
        initCollections() {
            this.paginator(1);
            this.inited = true;
        },

        paginator(page = 1) {
            this.profileService.collections(page)
                .then(data => this.collections = data);
        },

        unlike(lecture, index) {
            this.profileService.post(lecture.likeable.toggle)
                .then(resp => {
                    this.$delete(this.collections.data, index);
                });
        }
    },

    computed: {
        hasCollections() {
            return this.collections && this.collections.data.length > 0;
        }
    },

    components: {
        'lecture-card': LectureCard,
        'pagination': Pagination
    }
}
</script>
