import 'jasny-bootstrap/js/fileinput';
import { ajaxForm, toast, swalWithBootstrapButtons } from '../../helpers/utils';
import bus from "../components/bus";

$(function () {
    // 头像类型切换
    $('[name=avatar_type]').on('click', function () {
        const $this = $(this),
            avatar = $this.data('avatar');
        
        $('.fileinput-new').find('img').prop('src', avatar ? avatar : '/img/default-avatar.png');
        
        $('.fileinput-operate').toggleClass('d-none', $this.val() != 'storage');
    }).filter(':checked').trigger('click');

    // 编辑个人资料
    ajaxForm($('#profileForm'), data => {
        toast(data.message);
    }, err => {
        toast(err.data.errors
            ? _.chain(err.data.errors).values().flatten().value().join('\n')
            : err.data.message, 'snackbar-error');
    });

    // 绑定手机
    ajaxForm($('#bindMobileForm'), data => {
        toast(data.message);
        $('#bindMobileForm').find(':submit').attr('disabled', true);
        location.reload();
    }, err => {
        toast(err.data.errors ?
            _.chain(err.data.errors).values().flatten().value().join('\n') :
            err.data.message, 'snackbar-error');
    });
    
    // 安全信息修改
    ajaxForm($('#securityForm'), data => {
        toast(data.message);
        $('#securityForm').find(':submit').attr('disabled', true);

        if (data.logout) {
            setTimeout(() => {
                location.href = data.logout;
            }, data.seconds * 1000);
        }
    }, err => {
        toast(err.data.errors ?
            _.chain(err.data.errors).values().flatten().value().join('\n') :
            err.data.message, 'snackbar-error');
    });
    
    // 解绑第三方登录
    $('.btn-unbind-socialite').on('click', function (e) {
        e.preventDefault();

        const $this = $(this),
            url = $(this).attr('href');

        swalWithBootstrapButtons({
            title: '确定解除绑定吗?',
            text: '您可以稍后重新进行绑定',
            type: 'warning',
            confirmButtonText: '确定',
            cancelButtonText: '我再想想',
            showCancelButton: true
        }).then(result => {
            if (result.value) {
                axios.delete(url).then(resp => {
                    toast(resp.data.message);
                    $this.closest('.fileinput').remove();
                });
            }     
        });
    });

    $('.btn-edit-profile').on('click', function (e) {
        e.preventDefault();

        $('#profileSetting').tab('show');
    });

    $('.emit-to-vue').on('show.bs.tab', function (e) {
        let $this = $(this),
            $content = $($this.attr('href')),
            event = $this.data('event');

        if ($content.find('.inited').length > 0) {
            return true;
        }

        bus.$emit(event);
    }).eq(0).trigger('show.bs.tab');
});
