// form validation
$(window).on("load", function () {
    $(".needs-validation").on("submit", function (e) {
        if (this.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            $(this).find(':submit').attr('disabled', false);
        }
        $(this).addClass("was-validated");
    });
});
