import { BaseService } from "./BaseService";
import { UserApi } from "../helpers/config";

export class ProfileService extends BaseService {
    /**
     * 用户总览接口
     *
     * @returns {Promise}
     */
    overview() {
        return this.get(UserApi.dashboard);
    }

    /**
     * 用户sig
     *
     * @returns {Promise}
     */
    sig() {
        return this.post(UserApi.sig);
    }

    /**
     * 用户收藏接口
     *
     * @param {Integer} page
     */
    collections(page = 1) {
        return this.get(UserApi.collections, { page: page });
    }

    /**
     * 取消收藏
     *
     * @param {Integer} collection
     */
    unStar(collection) {
        return this.delete(UserApi.unStar.replace("{collection}", collection));
    }

    /**
     * 订单列表
     *
     * @param {Integer} page
     * @param {Object} filters 过滤参数
     */
    orders(page = 1, filters = {}) {
        const data = Object.assign({}, filters, { page: page });

        return this.get(UserApi.orders, data);
    }

    removeOrder(order) {
        return this.delete(UserApi.deleteOrder.replace("{order}", order.sn));
    }

    payOrder(order) {
        return this.get(UserApi.payOrder.replace('{order}', order.sn));
    }
}
