
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../bootstrap-material-design';
import 'bootstrap-material-design';
// import '../plugins';

// include arrive to watch dynamically created elements generated by vuejs
// import 'arrive';

// snack and toast message
import 'snackbarjs';

// all vue components loaded here
import './components/auth/vue';

// import "./auth/app";
// import 'now-ui-kit/assets/js/now-ui-kit';

// begin material-kit scripts
import 'material-kit/assets/js/material-kit';

// after all core js loaded
import '../helpers/needs-validation';   // base form validation
import './helpers/contact-map';         // baidu map in contact page
import './helpers/profile';             // profile scripts
