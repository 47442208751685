<template>
    <div v-if="inited" class="row" :class="elClass">
        <div class="col-md-12 ml-auto mr-auto ">
            <h4 class="title">最近浏览</h4>
            <div v-if="hasRecentlyVisitedRecords" class="row collections">
                <div class="col-md-3" v-for="(lecture, index) in overviews.recent_lectures" :key="index">
                    <lecture-card elClass="card-less-padding" :lecture="lecture"></lecture-card>
                </div>
            </div>
            <h6 v-else class="text-center text-muted">你还没有访问任何视频讲座</h6>
        </div>
        <!-- <div class="col-md-2 mr-auto ml-auto stats">
            <h4 class="title">我的统计</h4>
            <ul class="list-unstyled">
                <li v-if="overviews.comments">
                    <b>{{ overviews.comments }}</b> 条留言
                </li>
                <li v-if="overviews.collections">
                    <b>{{ overviews.collections }}</b> 个收藏
                </li>
            </ul> -->
            <!-- <hr> -->
            <!-- <h4 class="title">About his Work</h4>
            <p class="description">French luxury footwear and fashion. The footwear has incorporated shiny, red-lacquered soles that have become
                his signature.</p>
            <hr>
            <h4 class="title">Focus</h4>
            <span class="badge badge-primary">Footwear</span>
            <span class="badge badge-rose">Luxury</span> -->
        <!-- </div> -->
    </div>
</template>

<script>
import { ProfileService } from "../../../services/ProfileService";
import LectureCard from "../../cards/LectureCard.vue";

export default {
    name: 'ProfileOverview',

    props: {
        elClass: {
            type: [Object, String]
        }
    },

    data() {
        return {
            profileService: null,
            inited: false,
            overviews: {}
        }
    },

    mounted() {
        this.profileService = new ProfileService;

        this.profileService.hub().$on('init-overview', e => {
            this.initOverview();
        });
    },

    methods: {
        initOverview() {
            this.profileService.overview()
                .then(data => this.overviews = data);

            this.inited = true;
        }
    },

    computed: {
        hasRecentlyVisitedRecords() {
            if (this.overviews.recent_lectures && this.overviews.recent_lectures.length > 0) {
                return true;
            }

            return false;
        }
    },

    components: {
        'lecture-card': LectureCard
    }
}
</script>
