<template>
    <div v-if="inited" :class="elClass">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="form-inline mb-3 justify-content-end">
                    <div class="form-group">
                        <select v-model="filters.status" class="form-control">
                            <option v-for="(option, index) in statusList" :key="index" :value="option.value">{{ option.text }}</option>
                        </select>
                    </div>
                    <div class="form-group ml-3">
                        <input v-model="filters.q" type="text" class="form-control form-line-control-with-select" placeholder="输入需要查询的订单号">
                    </div>
                    <button type="button" class="btn btn-info btn-fab ml-3" @click="filterOrders"><i class="fa fa-search"></i></button>
                    <button type="button" class="btn btn-light btn-fab ml-1" @click="initOrders"><i class="fa fa-redo"></i></button>
                </div>
                <table class="table">
                    <tbody class="thead-light">
                        <tr class="small">
                            <td width="320">宝贝</td>
                            <td width="70">单价</td>
                            <td width="50">数量</td>
                            <td width="80">实付款</td>
                            <td width="80">交易状态</td>
                            <td>操作</td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="hasOrders">
                    <order-card
                        v-for="(order, index) in orders.data"
                        :key="index"
                        :order="order"
                        @remove-order="removeOrder(order, index)"
                        @pay-order="getQrCode(order, index)"
                    ></order-card>

                    <pagination :data="orders" @pagination-change-page="paginator"></pagination>
                </div>
                <h3 v-else class="text-muted text-center">尚未有任何订单</h3>
            </div>
        </div>

        <div id="orderPaymentDialog" class="modal" data-backdrop="false" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0">支付订单</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="qrCodes" class="row row-paycode">
                            <div v-for="(qrcode, gateway) in qrCodes" :key="gateway" class="col">
                                <p><span class="btn btn-fab btn-sm" :class="btnGateway(gateway)"><i class="fab" :class="faGateway(gateway)"></i></span></p>
                                <p class="mb-0"><img :src="qrcode" class="mb-0" alt="qrcode"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ProfileService } from "../../../services/ProfileService";
import { OrderService } from "../../../services/OrderService";
import OrderCard from "../../cards/OrderCard.vue";
import Pagination from 'laravel-vue-pagination';
import { toast } from '../../../../helpers/utils';

export default {
    name: 'ProfileOrders',

    props: {
        elClass: {
            type: [Object, String]
        }
    },

    data() {
        return {
            profileService: null,
            orderService: null,
            inited: false,
            orders: null,
            qrCodes: null,
            filters: {
                status: null,
                q: null
            },
            statusList: [
                {
                    value: null,
                    text: '请选择订单状态'
                },
                {
                    value: 0,
                    text: '尚未支付'
                },
                {
                    value: 1,
                    text: '支付成功'
                },
                {
                    value: 2,
                    text: '支付失败'
                }
            ]
        }
    },

    mounted() {
        this.profileService = new ProfileService;

        this.orderService = new OrderService;

        this.profileService.hub().$on('init-orders', e => {
            this.initOrders();
        });

        this.orderService.hub().$on('paied-successful', e => {
            toast('支付成功!');
            $("#orderPaymentDialog").modal('hide');
            this.qrCodes = null;
            this.initOrders();
        });
    },

    methods: {
        initOrders() {
            this.reset();
            this.paginator(1);
            this.inited = true;
        },

        filterOrders() {
            this.paginator();
        },

        reset() {
            this.filters = {
                q: null,
                status: null
            }
        },

        paginator(page = 1) {
            this.profileService.orders(page, this.filters)
                .then(data => this.orders = data);
        },

        removeOrder(order, index) {
            this.profileService.removeOrder(order)
                .then(data => {
                    toast(data.message);
                    this.$delete(this.orders.data, index);

                    if (!this.orders.data.length && this.orders.meta.current_page > 1) {
                        this.paginator(this.orders.meta.current_page - 1);
                    }
                })
                .catch(err => toast(err.data.message, 'snackbar-error'));
        },

        getQrCode(order, index) {
            this.profileService.payOrder(order)
                .then(data => {
                    this.qrCodes = data;
                    this.orderService.joinOrderChannel(order.sn);

                    $("#orderPaymentDialog").modal('show');
                }).catch(err => {
                    // const response = err.response;
                    console.log(err.data.message);
                    Swal.fire(err.data.message, '出错啦~', 'error');
                });;
        },

        faGateway(gateway) {
            if (gateway == 'wechat') {
                gateway = 'weixin';
            }

            return 'fa-' + gateway;
        },

        btnGateway(gateway) {
            if (gateway == 'wechat') {
                gateway = 'weixin';
            }

            return 'btn-' + gateway;
        }
    },

    computed: {
        hasOrders() {
            return this.orders && this.orders.data.length > 0;
        }
    },

    components: {
        'order-card': OrderCard,
        'pagination': Pagination
    }
}
</script>

<style lang="scss" scoped>
.form-line-control-with-select {
    height: calc(2.4375rem + 2px);
}
</style>
