var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inited
    ? _c("div", { class: _vm.elClass }, [
        _vm.hasCollections
          ? _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.collections.data, function (collect, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-md-3" },
                  [
                    _c("lecture-card", {
                      attrs: {
                        "el-class": "card-less-padding",
                        lecture: collect.lecture,
                        remove: {
                          enable: true,
                          desc: "你可以稍后重新收藏",
                          title: "是否移除该收藏?",
                        },
                      },
                      on: {
                        "remove-lecture": function ($event) {
                          return _vm.unlike(collect.lecture, index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _c("h3", { staticClass: "text-muted text-center" }, [
              _vm._v("尚未收藏任何条目"),
            ]),
        _vm._v(" "),
        _vm.hasCollections
          ? _c(
              "div",
              { staticClass: "d-flex mt-2 justify-content-end" },
              [
                _c("pagination", {
                  attrs: { data: _vm.collections },
                  on: { "pagination-change-page": _vm.paginator },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }