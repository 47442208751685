<template>
    <table class="table table-bordered">
        <thead class="thead-light">
            <tr>
                <th class="text-left small" colspan="6">
                    <strong>{{ order.created_at }}</strong> <span class="text-muted ml-3">订单编号: {{ order.sn }}</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in order.items" :key="index">
                <td class="text-left" width="320">
                    <div class="media">
                        <div class="media-left mr-3">
                            <img :src="item.goods.thumb" alt="thumb" class="rounded mb-0">
                        </div>
                        <div class="media-body">
                            <h6 class="my-0">{{ item.goods.name }}</h6>
                            <p class="small mb-0">观看天数: {{ findItemOption(item.options, 'viewable_days') }}</p>
                        </div>
                    </div>
                </td>
                <td width="70">
                    <p v-if="itemOriginalPrice(item)" class="text-line-through text-muted mb-0">￥{{ itemOriginalPrice(item) }}</p>
                    <p class="mb-0">￥{{ item.price }}</p>
                </td>
                <td width="50">{{ item.count }}</td>
                <td v-if="index === 0" :rowspan="itemRowSpan(order.items)" width="80">
                    <p v-if="orderOriginalPrice" class="text-line-through text-muted mb-0">￥{{ orderOriginalPrice }}</p>
                    <p class="font-weight-bold mb-0">￥{{ order.sub_total }}</p>
                </td>
                <td v-if="index === 0" :rowspan="itemRowSpan(order.items)" width="80">
                    <small>{{ order.status_text }}</small>
                </td>
                <td v-if="index === 0" :rowspan="itemRowSpan(order.items)">
                    <p><a href="#" class="btn btn-light btn-sm" @click.prevent="removeOrder(order)">{{ removeButtonText(order) }}</a></p>
                    <p v-if="canContinuePay(order)">
                        <a href="#" class="btn btn-sm btn-warning" @click.prevent="getPaymentCode(order)">立即支付</a>
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { swalWithBootstrapButtons } from "../../../helpers/utils";

export default {
    name: 'OrderCard',

    props: {
        elClass: {
            type: [String, Object]
        },

        order: {
            type: Object,
            required: true
        }
    },

    data() {
        return {

        }
    },

    methods: {
        itemRowSpan(items) {
            return items.length;
        },

        findItemOption(options, key) {
            if (options && options[key]) {
                return options[key];
            }

            return null;
        },

        itemOriginalPrice(item) {
            return item.price != item.goods.price ? item.goods.price : false;
        },

        removeOrder(order) {
            swalWithBootstrapButtons({
                title: '删除订单',
                text: '删除后您将无法恢复该订单!',
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '我再想想',
                showCancelButton: true
            }).then(result => {
                if (result.value) {
                    this.$emit('remove-order');
                }
            });
        },

        removeButtonText(order) {
            return order.status == 1 ? '删除订单' : '取消订单';
        },

        canContinuePay(order) {
            return order.status != 1;
        },

        getPaymentCode(order) {
            this.$emit('pay-order', order);
        }
    },

    computed: {
        orderOriginalPrice() {
            return this.order.total != this.order.sub_total ? this.order.total : false;
        }
    }
}
</script>

<style lang="scss" scoped>
.media-left {
    width: 10rem;
}

.text-line-through {
    text-decoration: line-through !important;
}
</style>
